import { VcClientError } from "./vc-client-error";
import { VcClientApiResult } from "./vc-client-api-result";

export class VcClientApiError extends VcClientError {
	private httpStatusCode : number;

	private headers : [string, string][];

	private result : VcClientApiResult<unknown> | null;

	public constructor(
		message : string,
		httpStatusCode : number,
		headers? : [string, string][],
		result? : VcClientApiResult<unknown>
	) {
		super(message);

		if(Error.captureStackTrace) {
			Error.captureStackTrace(this, VcClientApiError);
		}

		this.name = "VcClientApiError";

		this.httpStatusCode = httpStatusCode;
		this.headers = Array.from(headers || []);
		this.result = result || null;
	}

	public getHttpStatusCode() {
		return this.httpStatusCode;
	}

	public getHeaders() {
		return Array.from(this.headers);
	}

	public getResult() {
		return this.result;
	}
}
