import { VcClientApiResult } from "./vc-client-api-result";

export class VcClientApiResponse<T> {
	private statusCode : number;

	private headers : [string, string][];

	private body : VcClientApiResult<T> | null;

	public constructor(
		statusCode : number,
		headers : [string, string][],
		body : VcClientApiResult<T> | null
	) {
		this.statusCode = statusCode;
		this.headers = Array.from(headers || []);
		this.body = body;
	}

	public getStatusCode() {
		return this.statusCode;
	}

	public getHeaders() {
		return Array.from(this.headers);
	}

	public getBody() {
		return this.body;
	}
}
