/**
 *	@see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/globalThis
 */
export function findGlobalObject() : (typeof globalThis | void) {
  return (testIfGlobalObject(("object" === typeof window && window) || void 0)
		|| testIfGlobalObject("object" === typeof self && self)
		|| testIfGlobalObject("object" === typeof global && global)
		|| ((function (this : typeof globalThis | void) {
			return this;
		})())
		|| Function("return this")());
}

function testIfGlobalObject(it : unknown) {
	return (((it && (it as typeof globalThis).Math === Math) && it) || void 0);
}
