import { VcClientTokenStorage } from "./vc-client-token-storage";

export class VcClientInMemoryTokenStorage implements VcClientTokenStorage {
	#map : Record<string, string>;

	public constructor() {
		this.#map = {};
	}

	public async has(key : string) {
		if("string" !== typeof key) {
			throw new TypeError("'key' must be a string");
		}

		return key in this.#map;
	}

	public async load(key : string) {
		if(!this.has(key)) {
			throw new Error("There is no saved token grant result");
		}

		return this.#map[key];
	}

	public async save(
		key : string,
		value : string
	) {
		if("string" !== typeof key) {
			throw new TypeError("'key' must be a string");
		}

		if("string" !== typeof value) {
			throw new TypeError("'value' must be a string");
		}

		this.#map[key] = value;
	}

	public async remove(key : string) {
		if((await this.has(key))) {
			delete this.#map[key];
		}
	}
}
