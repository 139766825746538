import * as jose from "jose";

import { VcClientApiLmsRequestTokenGrantRequestParam } from "./vc-client-api-token-grant-request-param";

export class VcClientApiLmsRequestTokenBuilder {
	private _requestToken : string | null;

	private _userCd : string | null;

	private _publicKey : string | null;

	private _publicSecret : string | null;

	private _expiresInSeconds : number | null;

	public constructor() {
		this._requestToken = null;
		this._userCd = null;
		this._publicKey = null;
		this._publicSecret = null;
		this._expiresInSeconds = null;
	}

	public requestToken(v : string) {
		this._requestToken = v;

		return this;
	}

	public userCd(v : string) {
		this._userCd = v;

		return this;
	}

	public publicKey(v : string) {
		this._publicKey = v;

		return this;
	}

	public publicSecret(v : string) {
		this._publicSecret = v;

		return this;
	}

	public expiresInSeconds(v : number) {
		this._expiresInSeconds = v;

		return this;
	}

	public async build() {
		return new VcClientApiLmsRequestTokenGrantRequestParam(await this.createRequestToken());
	}

	private async createRequestToken() {
		const {
			_requestToken,
			_userCd,
			_publicKey,
			_publicSecret,
			_expiresInSeconds,
		} = this;

		let finalResult : string;

		if("string" === typeof _requestToken) {
			finalResult = _requestToken;
		} else {
			if("string" !== typeof _publicKey) {
				throw new Error("'publicKey' is not set");
			}
			if("string" !== typeof _publicSecret) {
				throw new Error("'publicSecret' is not set");
			}

			const jwt = await (new jose.SignJWT({
				uId : _userCd,
				iss : _publicKey,
				exp : ((Date.now() + ((_expiresInSeconds || 0) * 1000)) / 1000)
			})
				.setProtectedHeader({
					typ : "JWT",
					alg : "HS256"
				}))
				.sign(new TextEncoder().encode(_publicSecret));

			finalResult = jwt;
		}

		return finalResult;
	}
}
