export class VcClientError extends Error {
	public constructor(
		message? : string,
		cause? : Error
	) {
		super(message);

		if(Error.captureStackTrace) {
			Error.captureStackTrace(this, VcClientError);
		}

		this.name = "VcClientError";
		if(("cause" in this) && cause) {
			this.cause = cause;
		}
	}
}
