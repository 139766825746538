import { findGlobalObject } from "./find-global-object";
import { VcClientWebStorageToTokenStorageAdapter } from "./vc-client-web-storage-to-token-storage-adapter";

type SessionStorage = WindowSessionStorage["sessionStorage"];

export class VcClientSessionStorageToTokenStorageAdapter extends VcClientWebStorageToTokenStorageAdapter {
	public constructor(sessionStorage? : SessionStorage) {
		super(selectSessionStorage(sessionStorage) as SessionStorage);
	}
}

function selectSessionStorage(sessionStorage? : SessionStorage) {
	if(!sessionStorage) {
		const globalObject = findGlobalObject();
		if(globalObject && "sessionStorage" in globalObject) {
			sessionStorage = globalObject.sessionStorage;
		}
	}

	return sessionStorage;
}
