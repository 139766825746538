export abstract class VcClientApiTokenGrantRequestParam {
	private grantType : string;

	protected constructor(
		grantType : string
	) {
		this.grantType = grantType;
	}

	public getGrantType() {
		return this.grantType;
	}

	public abstract accept<R>(visitor : VcClientApiTokenGrantRequestParam.Visitor<R>) : R;
}

export namespace VcClientApiTokenGrantRequestParam {
	export interface Visitor<R> {
		visit(reqParam : VcClientApiLmsRequestTokenGrantRequestParam) : R;

		visit(reqParam : VcClientApiPasswordGrantRequestParam) : R;

		visit(reqParam : VcClientApiRefreshTokenGrantRequestParam) : R;
	}
}

export class VcClientApiLmsRequestTokenGrantRequestParam extends VcClientApiTokenGrantRequestParam {
	private requestToken : string;

	public constructor(
		requestToken : string
	) {
		super("LMS_REQUEST_TOKEN");

		this.requestToken = requestToken;
	}

	public getRequestToken() {
		return this.requestToken;
	}

	public accept<R>(visitor: VcClientApiTokenGrantRequestParam.Visitor<R>) {
			return visitor.visit(this);
	}
}

export class VcClientApiPasswordGrantRequestParam extends VcClientApiTokenGrantRequestParam {
	private username : string;

	private password : string;

	public constructor(
		username : string,
		password : string
	) {
		super("PASSWORD");

		this.username = username;
		this.password = password;
	}

	public getUsername() {
		return this.username;
	}

	public getPassword() {
		return this.password;
	}

	public accept<R>(visitor: VcClientApiTokenGrantRequestParam.Visitor<R>) {
		return visitor.visit(this);
	}
}

export class VcClientApiRefreshTokenGrantRequestParam extends VcClientApiTokenGrantRequestParam {
	private refreshToken : string;

	public constructor(
		refreshToken : string
	) {
		super("REFRESH_TOKEN");

		this.refreshToken = refreshToken;
	}

	public getRefreshToken() {
		return this.refreshToken;
	}

	public accept<R>(visitor: VcClientApiTokenGrantRequestParam.Visitor<R>) {
		return visitor.visit(this);
	}
}
