import { findGlobalObject } from "./find-global-object";
import { VcClientWebStorageToTokenStorageAdapter } from "./vc-client-web-storage-to-token-storage-adapter";

type LocalStorage = WindowLocalStorage["localStorage"];

export class VcClientLocalStorageToTokenStorageAdapter extends VcClientWebStorageToTokenStorageAdapter {
	public constructor(localStorage? : LocalStorage) {
		super(selectLocalStorage(localStorage) as LocalStorage);
	}
}

function selectLocalStorage(localStorage? : LocalStorage) {
	if(!localStorage) {
		const globalObject = findGlobalObject();
		if(globalObject && "localStorage" in globalObject) {
			localStorage = globalObject.localStorage;
		}
	}

	return localStorage;
}
