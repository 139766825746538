import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

import { VcClientError } from "./vc-client-error";
import { VcClientApiError } from "./vc-client-api-error";
import { VcClientApiRequest } from "./vc-client-api-request";
import { VcClientApiResponse } from "./vc-client-api-response";
import { VcClientApiResult } from "./vc-client-api-result";
import { VcClientApiTokenGrantRequestParam } from "./vc-client-api-token-grant-request-param";
import { VcClientAuthSession } from "./vc-client-auth-session";
import { VcClientTokenStorage } from "./vc-client-token-storage";

export namespace VcClient {
	export interface Option {
		lmsOrigin? : string;

		vcSiteDomain? : string;

		siteCd? : number;

		baseUrl? : string;

		origin? : string;

		pathPrefix? : string;

		responseTimeout? : number;

		tokenRefreshOffset? : number;

		tokenStorage? : VcClientTokenStorage | ((() => VcClientTokenStorage) | (() => Promise<VcClientTokenStorage>));
	}
}

export class VcClient {
	#option : VcClient.Option;

	#axios : AxiosInstance | null;

	#authSession : VcClientAuthSession | null;

	public constructor(option? : VcClient.Option) {
		this.#option = { ...(option || {}) };
		if("number" !== typeof this.#option.responseTimeout) {
			this.#option.responseTimeout = 10 * 60 * 1000;
		}
		if("number" !== typeof this.#option.tokenRefreshOffset) {
			this.#option.tokenRefreshOffset = 60 * 1000;
		}

		this.#axios = axios.create();

		this.#authSession = null;
	}

	public async isClosed() {
		return !this.#axios;
	}

	public async close() {
		await this.logout();

		this.#axios = null;
	}

	public async isLoggedIn() {
		return this.#authSession?.isOpened();
	}

	public async login(
		reqParam? : VcClientApiTokenGrantRequestParam | null,
		continueExistingAuthSession? : boolean
	) {
		await this.logout();

		let authSession : VcClientAuthSession;
		if(!reqParam && !!continueExistingAuthSession) {
			try {
				authSession = await VcClientAuthSession.open(
					this,
					null,
					{
						tokenRefreshOffset : this.#option.tokenRefreshOffset,
						tokenStorage : this.#option.tokenStorage,
					});
			} catch (
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				e) {
				authSession = await VcClientAuthSession.open(
					this,
					reqParam,
					{
						tokenRefreshOffset : this.#option.tokenRefreshOffset,
						tokenStorage : this.#option.tokenStorage,
					});
			}
		} else {
			authSession = await VcClientAuthSession.open(
				this,
				reqParam,
				{
					tokenRefreshOffset : this.#option.tokenRefreshOffset,
					tokenStorage : this.#option.tokenStorage,
				});
		}
		this.#authSession = authSession;
	}

	public async logout() {
		const authSession = this.#authSession;
		this.#authSession = null;
		if(authSession?.isOpened()) {
			await authSession.close();
		}
	}

	public async sendRequest<T>(req : VcClientApiRequest<T>) {
		const axiosInst = this.#axios;
		if(!axiosInst) {
			throw new VcClientError("The client is closed.");
		}

		const axiosReq = await this.#toAxiosRequest(req);

		let res : VcClientApiResponse<T> | null = null;
		try {
			const axiosRes = await axiosInst.request(axiosReq);

			res = new VcClientApiResponse<T>(
				axiosRes.status,
				Object
					.entries(axiosRes.headers)
					.filter((entry) => "string" === typeof entry[1]) as [string, string][],
				axiosRes.data
			);
		} catch(e) {
			if(e instanceof Error) {
				const axiosError = e as AxiosError;
				if(axiosError.isAxiosError) {
					const axiosRes = (axiosError.response as AxiosResponse<VcClientApiResult<unknown>, unknown>) || null;
					if(axiosRes) {
						throw new VcClientApiError(
							axiosError.message,
							axiosRes.status,
							(Object
								.entries(axiosRes.headers)
								.filter((entry) => "string" === typeof entry[1])) as [string, string][],
							axiosRes.data
						);
					} else {
						throw new VcClientError(e.message, e);
					}
				} else {
					throw new VcClientError(e.message, e);
				}
			} else if("string" === typeof e) {
				throw new VcClientError(e);
			} else {
				throw new VcClientError("An unknown error occurred.");
			}
		}

		return res;
	}

	async #toAxiosRequest<T>(req : VcClientApiRequest<T>) {
		if(!req) {
			throw new Error("'req' must be an object");
		}

		let currentReq = req;

		if(
			currentReq.isAuthorizationRequired()
			&& "string" !== typeof currentReq.getHeaders()["authorization"]
		) {
			if(!this.#authSession?.isOpened()) {
				throw new VcClientError("");
			}

			currentReq = await this.#authSession.decorate(currentReq);
		}

		const option = this.#option;

		const reqUrl = new URL(determineBaseUrl(currentReq, option) + currentReq.getPath());

		const parameters = currentReq.getParameters();
		for(const [parameterName, parameterValue] of parameters) {
			if(Array.isArray(parameterValue)) {
				for(const token of parameterValue) {
					reqUrl.searchParams.append(parameterName, token);
				}
			} else {
				reqUrl.searchParams.append(parameterName, parameterValue);
			}
		}

		const headers = {
			...Object
				.entries(currentReq.getHeaders())
				.filter((entry) => "string" === typeof entry[0])
				.map((entry) => [entry[0].toLowerCase(), entry[1]])
				.reduce((obj, entry) => ((obj[entry[0]] = entry[1]), obj), ({} as Record<string, string>))
		};

		if("string" === typeof option.lmsOrigin) {
			headers["x-lms-domain"] = option.lmsOrigin;
		}
		if("string" === typeof option.vcSiteDomain) {
			headers["x-vc-site-domain"] = option.vcSiteDomain;
		}
		if("number" === typeof option.siteCd) {
			headers["x-vc-site-cd"] = ("" + option.siteCd);
		}

		let contentType = currentReq.getContentType() || headers["content-type"];
		if(!contentType) {
			contentType = "application/json";
			headers["content-type"] = contentType;
		}

		let axiosReqData = null;
		const reqBody = currentReq.getRequestBody();
		if(reqBody && contentType.startsWith("application/json")) {
			axiosReqData = JSON.stringify(reqBody);
		}

		const axiosReq : AxiosRequestConfig = {
			url : reqUrl.toString(),
			method : currentReq.getMethod(),
			headers,
			data : axiosReqData
		};

		const responseTimeout = option.responseTimeout;
		if("number" === typeof responseTimeout) {
			axiosReq.timeout = responseTimeout;
		}

		return axiosReq;
	}
}

function determineBaseUrl(
	req : VcClientApiRequest<unknown>,
	option : VcClient.Option
) {
	let baseUrlText = req.getBaseUrl();
	if(!baseUrlText) {
		if(option.baseUrl) {
			baseUrlText = option.baseUrl;
		} else {
			if(option.origin) {
				baseUrlText = option.origin;
			} else if(option.vcSiteDomain) {
				baseUrlText = "https://" + option.vcSiteDomain;
			} else {
				throw new Error("Cannot determine the base URL");
			}

			if(option.pathPrefix) {
				baseUrlText += option.pathPrefix;
			}
		}
	}

	return baseUrlText;
}
