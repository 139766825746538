import { VcClientTokenStorage } from "./vc-client-token-storage";

export class VcClientWebStorageToTokenStorageAdapter implements VcClientTokenStorage {
	#webStorage : Storage;

	public constructor(webStorage : Storage) {
		if(!webStorage) {
			throw new TypeError("'webStorage' must be an object");
		}

		this.#webStorage = webStorage;
	}

	public async has(key : string) {
		if("string" !== typeof key) {
			throw new TypeError("'key' must be a string");
		}

		return !!this.#webStorage.getItem(key);
	}

	public async load(key : string) {
		const value = this.#webStorage.getItem(key);
		if(!value) {
			throw new Error("There is no saved token grant result");
		}

		return value;
	}

	public async save(
		key : string,
		value : string
	) {
		if("string" !== typeof key) {
			throw new TypeError("'key' must be a string");
		}

		if("string" !== typeof value) {
			throw new TypeError("'value' must be a string");
		}

		try {
			this.#webStorage.setItem(key, value);
		} catch(e) {
			const errToThrow = new Error("Failed to save the value");
			if("cause" in errToThrow) {
				errToThrow.cause = e;
			}
			throw errToThrow;
		}
	}

	public async remove(key : string) {
		if((await this.has(key))) {
			this.#webStorage.removeItem(key);
		}
	}
}
