import { VcClient } from "./vc-client";
import { VcClientApiHttpMethod } from "./vc-client-api-http-method";

export class VcClientApiRequest<R> {
	private client : VcClient;

	private method : VcClientApiHttpMethod;

	private baseUrl : string;

	private path : string;

	private parameters : [string, string][];

	private headers : Record<string, string>;

	private authorizationRequired : boolean;

	private contentType : string;

	private requestBody : object | null;

	public static builder<R>() {
		return new VcClientApiRequest.VcClientApiRequestBuilder<R>();
	}

	public constructor(
		client : VcClient,
		method : VcClientApiHttpMethod,
		baseUrl : string,
		path : string,
		parameters : [string, string][],
		headers : Record<string, string>,
		authorizationRequired : boolean,
		contentType : string,
		requestBody : object | null
	) {
		this.client = client;
		this.method = method;
		this.baseUrl = baseUrl;
		this.path = path;
		this.parameters = parameters;
		this.headers = headers;
		this.authorizationRequired = authorizationRequired;
		this.contentType = contentType;
		this.requestBody = requestBody;
	}

	public getClient() {
		return this.client;
	}

	public getMethod() {
		return this.method;
	}

	public getBaseUrl() {
		return this.baseUrl;
	}

	public getPath() {
		return this.path;
	}

	public getParameters() {
		return Array.from(this.parameters);
	}

	public getHeaders() {
		return ({
			...this.headers
		});
	}

	public isAuthorizationRequired() {
		return this.authorizationRequired;
	}

	public getContentType() {
		return this.contentType;
	}

	public getRequestBody() {
		return this.requestBody;
	}

	public async execute() {
		if(!this.client) {
			throw new Error("An instance of VcClient is not bound to this request");
		}

		return await this.client.sendRequest(this);
	}

	public async send() {
		return (await this.execute()).getBody();
	}

	public toBuilder() {
		return new VcClientApiRequest.VcClientApiRequestBuilder<R>(this);
	}
}

export namespace VcClientApiRequest {
	export class VcClientApiRequestBuilder<R> {
		private _client : VcClient | null;

		private _method : VcClientApiHttpMethod | null;

		private _baseUrl : string | null;

		private _path : string | null;
	
		private _parameters : [string, string][];
	
		private _headers : Record<string, string>;
	
		private _authorizationRequired : boolean;

		private _contentType : string | null;

		private _requestBody : object | null;

		public constructor(src? : VcClientApiRequest<R>) {
			this._client = (src ? src.getClient() : null);
			this._method = (src ? src.getMethod() : null);
			this._baseUrl = (src ? src.getBaseUrl() : null);
			this._path = (src ? src.getPath() : null);
			this._parameters = (src ? src.getParameters() : []);
			this._headers = (src ? src.getHeaders() : {});
			this._authorizationRequired = (src ? src.isAuthorizationRequired() : true);
			this._contentType = (src ? src.getContentType() : null);
			this._requestBody = (src ? src.getRequestBody() : null);
		}

		public client(v : VcClient) {
			this._client = v;

			return this;
		}

		public method(v : VcClientApiHttpMethod) {
			this._method = v;

			return this;
		}

		public baseUrl(v : string) {
			this._baseUrl = v;

			return this;
		}

		public path(v : string) {
			this._path = v;

			return this;
		}
	
		public parameters(v : [string, string][]) {
			this._parameters = v;

			return this;
		}

		public headers(v : Record<string, string>) {
			this._headers = v;

			return this;
		}
	
		public authorizationRequired(v : boolean) {
			this._authorizationRequired = v;

			return this;
		}

		public contentType(v : string) {
			this._contentType = v;

			return this;
		}

		public requestBody(v : object) {
			this._requestBody = v;

			return this;
		}

		public build() {
			return new VcClientApiRequest<R>(
				this._client as NonNullable<typeof this._client>,
				this._method as NonNullable<typeof this._method>,
				this._baseUrl as NonNullable<typeof this._baseUrl>,
				this._path as NonNullable<typeof this._path>,
				this._parameters,
				this._headers,
				this._authorizationRequired,
				this._contentType as NonNullable<typeof this._contentType>,
				this._requestBody
			);
		}
	}
}
