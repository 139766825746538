import { Instant, OffsetDateTime } from "@js-joda/core";

import { VcClient } from "./vc-client";
import { VcClientApiRequest } from "./vc-client-api-request";
import { VcClientApiRefreshTokenGrantRequestParam, VcClientApiTokenGrantRequestParam } from "./vc-client-api-token-grant-request-param";
import { VcClientInMemoryTokenStorage } from "./vc-client-in-memory-token-storage";
import { VcClientTokenStorage } from "./vc-client-token-storage";
import { VcClientError } from "./vc-client-error";
import { VcClientApiTokenGrantResult } from "./vc-client-api-token-grant-result";
import { VcClientApiHttpMethod } from "./vc-client-api-http-method";

type Timeout = ReturnType<typeof setTimeout>;
type TimeoutCallback = Parameters<typeof setTimeout>["0"];

const ITEM_KEY = "VcClientAuthSession";

export namespace VcClientAuthSession {
	export interface Option {
		tokenRefreshOffset? : number;

		tokenStorage? : VcClientTokenStorage | ((() => VcClientTokenStorage) | (() => Promise<VcClientTokenStorage>));
	}
}

export class VcClientAuthSession {
	#client : VcClient;

	#tokenStorage : VcClientTokenStorage;

	#opened : boolean;

	#tokenRefreshTimeout : Timeout | null;

	public static async open(
		client : VcClient,
		reqParam? : VcClientApiTokenGrantRequestParam | null,
		option? : VcClientAuthSession.Option
	) {
		if(!client) {
			throw new TypeError("'client' must be an object");
		}

		let tokenStorage : VcClientTokenStorage;
		const optionTokenStore = option?.tokenStorage;
		if("function" === typeof optionTokenStore) {
			tokenStorage = await optionTokenStore();
		} else if("object" === typeof optionTokenStore && !!optionTokenStore) {
			tokenStorage = optionTokenStore;
		} else {
			tokenStorage = new VcClientInMemoryTokenStorage();
		}

		const session = new VcClientAuthSession(
			client,
			tokenStorage
		);

		await session._open(
			reqParam,
			option);

		return session;
	}

	private constructor(
		client : VcClient,
		tokenStorage : VcClientTokenStorage
	) {
		this.#client = client;
		this.#tokenStorage = tokenStorage;
		this.#tokenRefreshTimeout = null;
		this.#opened = false;
	}

	public isOpened() {
		return this.#opened;
	}

	public async close() {
		if(this.isOpened()) {
			this.#opened = false;

			const tokenRefreshTimeout = this.#tokenRefreshTimeout;
			this.#tokenRefreshTimeout = null;
			if(tokenRefreshTimeout) {
				clearTimeout(tokenRefreshTimeout);
			}
		}
	}

	public async decorate<T>(request : VcClientApiRequest<T>) {
		if(!request) {
			throw new TypeError("'request' must be an object");
		}

		this.#assertIsOpened();

		const tokenGrantResult = await this.#loadTokenGrantResult();

		return request
			.toBuilder()
			.headers({
				...request.getHeaders(),
				"authorization" : `${tokenGrantResult.tokenType} ${tokenGrantResult.accessToken}`,
			})
			.build();
	}

	private async _open(
		reqParam? : VcClientApiTokenGrantRequestParam | null,
		option? : VcClientAuthSession.Option
	) {
		if(this.isOpened()) {
			throw new VcClientError("The auth session is already opened");
		}

		const tokenRefreshOffset = (option?.tokenRefreshOffset || 0);
		const doTokenRefreshCallback = () => {
			this.#doTokenRefresh(
				doTokenRefreshCallback,
				tokenRefreshOffset);
		};

		if(!reqParam) {
			if(!(this.#hasTokenGrantResult())) {
				throw new VcClientError("No access token has been issued");
			}

			const tokenCreateResult = await this.#loadTokenGrantResult();
			const refreshTokenExpiresAt = OffsetDateTime
				.parse(tokenCreateResult.issuedAt)
				.plusSeconds(tokenCreateResult.refreshTokenExpiresIn || 0);
			if(refreshTokenExpiresAt.isBefore(OffsetDateTime.now())) {
				throw new VcClientError("The refresh token has been invalidated");
			}

			const accessTokenExpiresAt = OffsetDateTime
				.parse(tokenCreateResult.issuedAt)
				.plusSeconds(tokenCreateResult.expiresIn || 0);
			if(accessTokenExpiresAt.isBefore(OffsetDateTime.now())) {
				doTokenRefreshCallback();
			} else {
				const diffMillis = Math.abs(accessTokenExpiresAt.toInstant().toEpochMilli() - OffsetDateTime.now().toInstant().toEpochMilli());
				this.#tokenRefreshTimeout = setTimeout(
					doTokenRefreshCallback,
					(tokenCreateResult.expiresIn * 1000) - tokenRefreshOffset - diffMillis
				);
			}
		} else {
			await this.#doInTokenCreateContext(async () => {
				const tokenCreateRes = await this.#client.sendRequest(VcClientApiRequest
					.builder<VcClientApiTokenGrantResult>()
					.method(VcClientApiHttpMethod.POST)
					.path("/auth/tokens")
					.authorizationRequired(false)
					.requestBody(reqParam)
					.build());
				const tokenCreateResult = tokenCreateRes.getBody()?.datas || null;
				if(!tokenCreateResult) {
					throw new VcClientError("Failed to issue a new access token for an unknown reason");
				}
				const beforeSave = Instant.now().toEpochMilli();
				await this.#saveTokenGrantResult(tokenCreateResult);
				const saveElapsed = Math.abs(Instant.now().toEpochMilli() - beforeSave);

				this.#tokenRefreshTimeout = setTimeout(
					doTokenRefreshCallback,
					(tokenCreateResult.expiresIn * 1000) - tokenRefreshOffset - saveElapsed
				);
			});
		}

		this.#opened = true;
	}

	#assertIsOpened() {
		if(!this.isOpened()) {
			throw new VcClientError("The auth session is not opened");
		}
	}

	async #hasTokenGrantResult() {
		return await this.#tokenStorage.has(ITEM_KEY);
	}

	async #loadTokenGrantResult() {
		let result : VcClientApiTokenGrantResult;

		const item = await this.#tokenStorage.load(ITEM_KEY);
		if(item) {
			const tokenGrantResult = JSON.parse(item) as VcClientApiTokenGrantResult;
			if(!tokenGrantResult.accessToken) {
				throw new VcClientError("The stored token grant result is invalid");
			} else {
				result = tokenGrantResult;
			}
		} else {
			throw new VcClientError("There is no saved token grant result");
		}

		return result;
	}

	async #saveTokenGrantResult(tokenGrantResult : VcClientApiTokenGrantResult) {
		if(!tokenGrantResult) {
			throw new TypeError("'tokenGrantResult' must be an object");
		}

		let item = null;
		try {
			item = JSON.stringify(tokenGrantResult);
		} catch(e) {
			const errToThrow = new VcClientError("Failed to stringify parameter 'tokenGrantResult'");
			if("cause" in errToThrow) {
				errToThrow.cause = e;
			}
			throw errToThrow;
		}

		try {
			this.#tokenStorage.save(ITEM_KEY, item);
		} catch(e) {
			const errToThrow = new VcClientError("Failed to save parameter 'tokenGrantResult'");
			if("cause" in errToThrow) {
				errToThrow.cause = e;
			}
			throw errToThrow;
		}
	}

	async #doTokenRefresh(
		timeoutCallback : TimeoutCallback,
		nextTokenRefreshOffset : number
	) {
		if(this.isOpened()) {
			const tokenGrantResult = await this.#loadTokenGrantResult();
			const refreshToken = tokenGrantResult.refreshToken || null;
			if(refreshToken) {
				this.#doInTokenCreateContext(async () => {
					const tokenCreateRes = await this.#client.sendRequest(VcClientApiRequest
						.builder<VcClientApiTokenGrantResult>()
						.method(VcClientApiHttpMethod.POST)
						.path("/auth/tokens")
						.authorizationRequired(false)
						.requestBody(new VcClientApiRefreshTokenGrantRequestParam(refreshToken))
						.build());
					const tokenCreateResult = tokenCreateRes.getBody()?.datas || null;
					if(!tokenCreateResult) {
						throw new VcClientError("Failed to issue a new access token for an unknown reason");
					}
					const beforeSave = Instant.now().toEpochMilli();
					await this.#saveTokenGrantResult(tokenCreateResult);
					const saveElapsed = Math.abs(Instant.now().toEpochMilli() - beforeSave);

					this.#tokenRefreshTimeout = setTimeout(
						timeoutCallback,
						(tokenCreateResult.expiresIn * 1000) - nextTokenRefreshOffset - saveElapsed
					);
				});
			}
		}
	}

	async #doInTokenCreateContext<R>(supplier : () => Promise<R>) {
		let result : R;

		try {
			result = await supplier.call(this);
		} catch(e) {
			await this.close();

			if(e instanceof Error) {
				throw e;
			} else {
				throw new VcClientError("Failed to issue a new access token for an unknown reason");
			}		
		}

		return result;
	}
}
